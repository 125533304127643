// Ozempic, Saxenda, and Rybelsus at all dosages
export const patientPreferenceOptions = [
  {
    medication: "Ozempic",
    options: [
      {
        name: "Ozempic 0.25mg",
        id: "6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.25mg",
      },
      {
        name: "Ozempic 0.5mg",
        id: "TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.5mg",
      },
      {
        name: "Ozempic 1mg",
        id: "QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "1mg",
      },
    ],
  },
  {
    medication: "Saxenda",
    options: [
      {
        name: "Saxenda",
        id: "QUYoE53d4nNIxP85Y4XRgsYx9ejcm5Nz",
        quantity: 3,
        dispense: "ml",
        refills: 3,
        strength: "None",
      },
    ],
  },
  {
    medication: "Rybelsus",
    options: [
      {
        name: "Rybelsus 3mg",
        id: "oVJ1ceDCPrKlL8CJdPUekcDSxEAG8Qzo",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "3mg",
      },
      {
        name: "Rybelsus 7mg",
        id: "vkclzvpo6Ak71P9bocQNgS0N9THeCwif",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "7mg",
      },
      {
        name: "Rybelsus 14mg",
        id: "rWopjzc2M8TWjQ7wGHA4YEAKpaoVrxHd",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "14mg",
      },
    ],
  },
  {
    medication: "Wegovy",
    options: [
      {
        name: "Wegovy 0.25mg",
        id: "yrbqXSVX9mrMpth91OEszx7bPGi4hMXH",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "0.25 mg/0.5 mL (0.25 mg dose)",
      },
      {
        name: "Wegovy 0.5mg",
        id: "fV5uaGx2IJ9VeQ0mxoclbYq4bgFZZSxO",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "0.5 mg/0.5 mL (0.5 mg dose)",
      },
      {
        name: "Wegovy 1mg",
        id: "NeEPvtesfrCakHkIhTpkJcihiyZHbyoA",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "1 mg/0.5 mL (1 mg dose)",
      },
      {
        name: "Wegovy 1.7mg",
        id: "eeSFJOtlyVrpfiKckXjnFwzEmUrtjXav",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "1.7 mg/0.75 mL (1.7 mg dose)",
      },

      {
        name: "Wegovy 2.4mg",
        id: "DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "2.4 mg/0.75 mL (2.4 mg dose)",
      },
    ],
  },
];

const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      prev.push({
        answer: {
          name: `${opt.name} - ${opt.quantity} ${opt.dispense} - (Refills - ${opt.refills})`,
          group,
          optionId: opt.id,
          optionData: opt,
        },
      });
    });
    return prev;
  },
  []
);

export const questionnaire = [
  {
    question: "Please Select Your preferred medication option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  },
  {
    question: "What is your sex assigned at birth?",
    answers: [
      // TODO: Pregnant + breastfeeding Follow Up Q
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "What is your height?",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "What is your weight?",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  {
    question:
      "Please list what you’re allergic to and the reaction that each allergy causes.",
    answers: ["allergies"],
    subText:
      "Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.",
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list your medical conditions and any prior surgeries:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Have you tried or are you currently using any medications to help with weight loss?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question: "Which medications and were they effective for you?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Are you likely to increase your physical fitness and activity, in addition to taking your new medication, if prescribed?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question:
      "Do you have (or have you had) any of the following conditions? Choose All Applicable",
    answers: [
      {
        answer: "Alcohol overuse",
        showConditional: false,
      },
      {
        answer: "Admitted to a psychiatric facility",
        showConditional: false,
      },
      {
        answer: "Attempted suicide",
        showConditional: false,
      },
      {
        answer: "Bariatric surgery",
        showConditional: false,
      },
      {
        answer: "Bipolar disorder",
        showConditional: false,
      },
      {
        answer: "Bleeding or clotting disorder",
        showConditional: false,
      },
      {
        answer: "Chronic kidney (renal) failure",
        showConditional: false,
      },
      { answer: "Liver disease", showConditional: false },
      { answer: "Depression", showConditional: false },
      { answer: "Diabetes", showConditional: false },
      { answer: "Illicit Drug use", showConditional: false },
      { answer: "Eating disorders (anorexia/bulimia)", showConditional: false },
      { answer: "Gallbladder disease", showConditional: false },
      { answer: "Heart attack", showConditional: false },
      { answer: "Heart disease", showConditional: false },
      { answer: "High blood pressure", showConditional: false },
      { answer: "High cholesterol", showConditional: false },
      {
        answer: "Infection/tumor in your brain or spinal cord",
        showConditional: false,
      },
      { answer: "Low blood sugar (hypoglycemia)", showConditional: false },
      { answer: "Mental illness", showConditional: false },
      { answer: "Pancreatitis", showConditional: false },
      {
        answer:
          "A personal or family history of medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2",
        showConditional: false,
      },
      {
        answer: "Recently thoughts of hurting yourself or others",
        showConditional: false,
      },
      { answer: "Seizures", showConditional: false },
      { answer: "Sleep apnea", showConditional: false },
      { answer: "Stroke", showConditional: false },
      { answer: "Thyroid dysfunction", showConditional: false },
      { answer: "None of these apply", showConditional: false },
    ],
    type: "checkBox",
  },
  {
    question:
      "Has you or anyone in your family been diagnosed with thyroid cancer?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
];
